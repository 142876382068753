import React from 'react'
import SideNav, { Toggle, Nav, NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';
import '@trendmicro/react-sidenav/dist/react-sidenav.css';

const CTA = () => {
  return (
    <div className='cta_sidenav'>
        
    </div>
  )
}

export default CTA